<template>
    <div id="Prospects" class="m-0 p-0 row">

        <div class="col-12 d-flex flex-wrap p-0 m-0 justify-content-start justify-content-xl-end align-items-end py-2 py-xl-4">

            <div class="col-4 col-xxl-3 position-relative">
                <input @input="filterElements"
                    class="custom_input opensans-bold text-dark pl-3 w-100"
                    type="text"
                    v-model="search_query"
                    placeholder="Buscar"
                    />
                <img class="position-absolute"
                    style="width: 25px; height: 25px; right: 2rem; top: 0.5rem"
                    :src="lupa_icon_b" />
            </div>
            <div class="col-4 col-xxl-3">
                <ns-select @change="filterElements"
                    theme="light"
                    class="opensans-bold text-dark"
                    label="Filtrar por:"
                    variant="white"
                    :options="[{id: 'active', label: 'Elementos activos'}, {id: 'inactive', label:'Elementos inactivos'}, {id: 'all', label: 'Todos'}]"
                    v-model="filter"
                    full
                    rounded>
                </ns-select>
            </div>
            <div class="col-4 col-xxl-3 px-2 px-xl-5">
                <button @click.stop="prospects_modal = true;"
                    class="shadow_btn py-2 mr-3 w-100"
                    type="button">
                    Agregar Prospecto
                </button>
            </div>
        </div>

        <div class="col-12" v-if="loading">
            <ns-spinner size="sm" variant="success"/>
        </div>

        <div class="col-12" v-if="empty_search && !search_query && !loading">
            <p class="text-dark">
                No se encontraron elementos con el filtro seleccionado.
            </p>
        </div>

        <div class="col-12" v-if="empty_search && search_query && !loading">
            <p class="text-dark">
                No se encontraron elementos con esos parámetros de búsqueda.
            </p>
        </div>


        <div class="col-12" v-if="!loading && !fetch_error && !empty_search && prospects_data.length > 0">
            <ns-table :data="prospects_data" 
                :allow_headers="prospects_headers"
                color_headers="gray" color_headers_text="black" pagination_off
                color_body="white" border_body="white" color_body_text="black">
                <template v-slot:healty_condition="data">
                    {{ data.item.healty_condition == 'none' ? 'Ninguno' : data.item.healty_condition == 'hormonal_problems' ? 'Problemas Homonales' : data.item.healty_condition == 'hypertension' ? 'Hipertensión' : data.item.healty_condition == 'obesity' ? 'Obesidad' : data.item.healty_condition == 'hypothyroidism' ? 'Hipotiroidismo' : data.item.healty_condition == 'diabetes' ? 'Diabetes' : '' }}
                </template>
                <template v-slot:gender="data">
                    {{ data.item.gender == 'male' ? 'Masculino' : 'Femenino'}}
                </template>
                <template v-slot:active="data">
                    <span>
                        {{ data.item.active ? 'Activo' : 'Inactivo'}}
                    </span>
                </template>
                <template v-slot:actions="data">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-12 position-relative">
                            <button @click.stop="data.item.open = true"
                                class="w-100"
                                style="border: none; background: none;"
                                type="button">
                                Ver
                            </button>

                            <div @mouseleave="data.item.open = false"
                                class="position-absolute d-flex flex-column bg-white" 
                                style="width: 200px; height: 150px; z-index: 15; top: -5vh; right: 0vw;" v-if="data.item.open">
                                <button @click.stop="selectTableItem('edit', data.item.id_prospect, data.item)"
                                    class="bg-grey mx-3 mt-3"
                                    style="border: none;"
                                    type="button">
                                    Editar
                                </button>

                                <button @click.stop="selectTableItem('delete', data.item.id_prospect)" v-if="data.item.active"
                                    class="bg-grey mx-3 my-3"
                                    style="border: none;"
                                    type="button">
                                    Borrar
                                </button>

                                <button @click.stop="selectTableItem('activate', data.item.id_prospect)" v-if="!data.item.active"
                                    class="bg-grey mx-3 my-3"
                                    style="border: none;"
                                    type="button">
                                    Activar
                                </button>
                            </div>
                        </div>
                    </div>
                </template>
            </ns-table>
        </div>

        <!---- Paginator ----->

        <div class="col-12 py-3" v-if="!fetch_error && !empty_search">
            <div class="row justify-content-evenly align-items-end">

                <div class="col-12 col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-start">
                    <span class="mr-3 opensans-bold text-dark">
                        Filas:
                    </span>
                    
                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 50px;"
                        v-model="rows"
                        class="custom_input text-center"
                        :disabled="page > 1"
                        />
                    
                    <ns-select @change="filterElements"
                        theme="light"
                        class="opensans-bold text-dark ml-2"
                        style="width: 180px;"
                        variant="white"
                        :options="[{id: '1', label: 'Antiguos primero'}, {id: '2', label: 'Recientes primero'}]"
                        v-model="order_asc"
                        full>
                    </ns-select>
                </div>



                <div class="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end align-items-end pr-0 pr-xl-4 py-3 py-lg-0">

                    <button @click.stop="prev"
                        class="btn btn-pill btn-success"
                        type="button"
                        :disabled="page <= 1">
                        <img :src="arrow_left" />
                    </button>

                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="page"
                        class="custom_input text-center mx-4"
                        />


                    <button @click.stop="next"
                        class="btn btn-pill btn-success"
                        type="button"
                        :disabled="last_page">
                        <img :src="arrow_left" style="transform: rotateY(180deg);" />
                    </button>
                </div>
            </div>
        </div>

        <!----------- Modales  ------------->
        <ns-modal :show_modal="prospects_modal" bg_color="white" max_width="1100"
            overlay_close @onClose="prospects_modal = false">
            <div class="row w-100 justify-content-between montserrat-semibold">


                <div class="col-12 col-md-3">
                    <ns-select label="Calidad de sueño"
                        theme="light"
                        :options="[{id: 0, label: 'Seleccione'}, {id: 'sleep_but_not_rest', label: 'Duermo pero no descanso'}, {id: 'sleep_well', label: 'Duermo mis 8 horas'},{id: 'insomnio', label: 'Insomnio (menos de 4 horas)'}]"
                        v-model="id_sleep_quality"
                        variant="white"
                        rounded
                        full>
                    </ns-select>
                    <span class="text-danger" v-if="errors.id_sleep_quality">
                        {{ errors.id_sleep_quality }}
                    </span>
                </div>

                <div class="col-12 col-md-3">
                    <ns-select-image @selected:option="checkComponentValue"
                        label="Nivel de Estres:"
                        :current="current_option"
                        :options="stress_options"
                        bg_color="light"
                        theme="light"
                        full
                        rounded>
                    </ns-select-image>
                    <span class="text-danger" v-if="errors.id_stress">
                        {{ errors.id_stress }}
                    </span>
                </div>

                <div class="col-12 col-md-3">
                    <ns-select label="Actividad Física"
                        theme="light"
                        :options="[{id: 0, label: 'Seleccione...'},{id: 'sporadically', label: 'Esporadicamente'}, {id: '5_times_per_week', label: '5 veces por semana'}, {id: '2_times_per_week', label: '2 veces por semana'},{id: 'sedentary', label: 'Nula o sedentarismo'}]"
                        v-model="id_physical_activity"
                        variant="white"
                        rounded
                        full>
                    </ns-select>
                    <span class="text-danger" v-if="errors.id_physical_activity">
                        {{ errors.id_physical_activity }}
                    </span>
                </div>

                <div class="col-12 col-md-3 ">
                    <ns-select label="Condición de Salud"
                        theme="light"
                        :options="[{id: 0, label: 'Seleccione...'},{id: 'hormonal_problems', label: 'Problemas hormonales'}, {id: 'hypertension', label: 'Hipertensión'}, {id: 'obesity', label: 'Obesidad'},{id: 'hypothyroidism', label: 'Hipotiroidismo'},{id: 'diabetes', label: 'Diabetes'}, {id: 'none', label: 'Ninguno'}]"
                        v-model="id_health_condition"
                        variant="white"
                        rounded
                        full>
                    </ns-select>
                    <span class="text-danger" v-if="errors.id_health_condition">
                        {{ errors.id_health_condition }}
                    </span>
                </div>

                <div class="col-12 col-md-3 my-3">
                    <ns-select label="Objetivo"
                        theme="light"
                        :options="[{id: 0, label: 'Seleccione...'},{id: 'keep_fit', label: 'Mantenerme en forma'}, {id: 'build_muscle_mass', label: 'Crear masa muscular'}, {id: 'lose_weight', label: 'Adelgazar o Perder peso'}, {id: 'best_condition_health', label: 'Mejorar Condición de Salud'}]"
                        v-model="id_objective"
                        variant="white"
                        rounded
                        full>
                    </ns-select>
                    <span class="text-danger" v-if="errors.id_objective">
                        {{ errors.id_objective }}
                    </span>
                </div>

                <div class="col-12 col-md-3 my-3">
                    <ns-select label="Edad"
                        theme="light"
                        :options="[{id: 0, label: 'Seleccione...'},{id: '56-more', label: '56 o más'}, {id: '45-55', label: '45 - 55'}, {id: '30-44', label: '30 - 44'}, {id: '18-29', label: '18 - 29'}]"
                        v-model="id_age"
                        variant="white"
                        rounded
                        full>
                    </ns-select>
                    <span class="text-danger" v-if="errors.id_age">
                        {{ errors.id_age }}
                    </span>
                </div>

                <div class="col-12 col-md-3 my-3">
                    <ns-input label="Altura en cm" 
                        _id="height"
                        theme="light"
                        placeholder="Altura en cm"
                        v-model="height"
                        type="number"
                        rounded
                        full>
                    </ns-input>
                    <span class="text-danger" v-if="errors.height">
                        {{ errors.height }}
                    </span>
                </div>

                <div class="col-12 col-md-3 my-3">
                    <ns-input label="Peso" 
                        _id="weight"
                        theme="light"
                        v-model="weight"
                        type="number"
                        rounded
                        full>
                    </ns-input>
                    <span class="text-danger" v-if="errors.weight">
                        {{ errors.weight }}
                    </span>
                </div>

                <div class="col-12 col-md-3">
                    <ns-select label="Género"
                        theme="light"
                        :options="[{id: 0, label: 'Seleccione...'},{id: 'male', label: 'Masculino'}, {id: 'female', label: 'Femenino'}]"
                        v-model="id_gender"
                        variant="white"
                        rounded
                        full>
                    </ns-select>
                    <span class="text-danger" v-if="errors.id_gender">
                        {{ errors.id_gender }}
                    </span>
                </div>

                <div class="col-12 col-md-3">
                    <ns-input label="Nombre" 
                        _id="name"
                        theme="light"
                        v-model="first_name"
                        type="text"
                        rounded
                        full>
                    </ns-input>
                    <span class="text-danger" v-if="errors.first_name">
                        {{ errors.first_name }}
                    </span>
                </div>

                <div class="col-12 col-md-6">
                    <ns-input label="Apellidos" 
                        _id="last_name"
                        theme="light"
                        v-model="last_name"
                        type="text"
                        rounded
                        full>
                    </ns-input>
                    <span class="text-danger" v-if="errors.last_name">
                        {{ errors.last_name }}
                    </span>
                </div>

                <div class="col-12 col-md-3 mt-3">
                    <ns-input label="Correo" 
                        _id="email"
                        theme="light"
                        v-model="email"
                        type="email"
                        rounded
                        full>
                    </ns-input>
                    <span class="text-danger" v-if="errors.email">
                        {{ errors.email }}
                    </span>
                </div>

                <div class="col-12 col-md-3 mt-3">
                    <ns-input label="Teléfono" 
                        _id="phone"
                        theme="light"
                        v-model="phone"
                        type="number"
                        rounded
                        full>
                    </ns-input>
                    <span class="text-danger" v-if="errors.phone">
                        {{ errors.phone }}
                    </span>
                </div>

                <div class="col-12 col-md-3 mt-3">
                    <ns-input label="Contraseña" 
                        _id="password"
                        theme="light"
                        v-model="password"
                        type="password"
                        rounded
                        full>
                    </ns-input>
                    <span class="text-danger" v-if="errors.password">
                        {{ errors.password }}
                    </span>
                </div>

                <div class="col-6 mt-3 montserrat-bold">
                    <div class="row justify-content-end pt-4">
                        <div class="col-7 d-flex justify-content-between p-0 align-items-end">
                            <button @click.stop="createEditElement"
                                class="btn btn-success"
                                style="border-radius: 15px;"
                                type="button">
                                Guardar
                            </button>
                            <button @click.stop="clearFields"
                                class="btn btn-danger"
                                style="border-radius: 15px;"
                                type="button">
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ns-modal>

        <ns-modal :show_modal="delete_modal" bg_color="white" max_width="680"
            overlay_close @onClose="delete_modal = false">
            <div class="row w-100 justify-content-between montserrat-semibold">

                <div class="col-12 text-center">
                    <p class="text-black">
                        ¿Esta seguro de eliminar este elemento?
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-center">
                    <button @click.stop="inactivateElement"
                        class="btn btn-danger btn-pill"
                        type="button">
                        Eliminar
                    </button>
                    <button @click.stop="clearFields"
                        class="btn btn-primary btn-pill ml-3"
                        type="button">
                        Cancelar
                    </button>
                </div>
                
            </div>
        </ns-modal>

        <ns-modal :show_modal="active_modal" bg_color="white" max_width="680"
            overlay_close @onClose="active_modal = false">
            <div class="row w-100 justify-content-between montserrat-semibold">

                <div class="col-12 text-center">
                    <p class="text-black">
                        ¿Esta seguro de activar este elemento?
                    </p>
                </div>

                <div class="col-12 d-flex justify-content-center">
                    <button @click.stop="activateElement"
                        class="btn btn-success btn-pill"
                        type="button">
                        Activar
                    </button>
                    <button @click.stop="clearFields"
                        class="btn btn-primary btn-pill ml-3"
                        type="button">
                        Cancelar
                    </button>
                </div>
                
            </div>
        </ns-modal>
    </div>
</template>

<script>

    //emojis
    import Angry from "ASSETS/icons/emoji-angry.svg"
    import Frown from "ASSETS/icons/emoji-frown.svg"
    import Laughing from "ASSETS/icons/emoji-laughing.svg"
    import Neutral from "ASSETS/icons/emoji-neutral.svg"
    import Smile from "ASSETS/icons/emoji-smile.svg"
    import lupa_icon_b from 'ASSETS/icons/lupa_icon_b.png'
    import arrow_left from 'ASSETS/icons/arrow_left.png'
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                Angry,
                Frown,
                Laughing,
                Neutral,
                Smile,
                lupa_icon_b,
                arrow_left,
                prospects_modal: false,
                delete_modal: false,
                active_modal: false,
                search_query: null,
                loading: true,
                fetch_error: false,
                empty_search: false,
                selected_id: null,
                prospects_headers: [
                    {key: 'first_name', label: 'Nombres'},
                    {key: 'last_name', label: 'Apellidos'},
                    {key: 'email', label: 'Correo electrónico'},
                    {key: 'gender', label: 'Genero'},
                    {key: 'age', label: 'Edad'},
                    {key: 'active', label: 'Estatus'},
                    {key: 'healty_condition', label: 'Problemas de salud'},
                    {key: 'actions', label: 'Acciones'}
                ],
                prospects_data: [],
                stress_options: [
                    {id: 0, label: 'Seleccione..', icon:''}, 
                    {id: 'angry', label: 'Enojado', icon: Angry}, 
                    {id: 'tired', label: 'Agobiado', icon: Frown},
                    {id: 'indifferent', label: 'Meh', icon: Neutral}, 
                    {id: 'happy', label: 'Nada', icon: Smile}, 
                    {id: 'very_happy', label: '¿Qué es eso?', icon: Laughing}
                ],
                id_sleep_quality: '0',
                current_option: [{id: 0, label: 'Seleccione..', icon:''}],
                id_stress: null,
                id_physical_activity: '0',
                id_health_condition: '0',
                id_objective: '0',
                id_age: '0',
                height: null,
                weight: null,
                id_gender: '0',
                first_name: null,
                last_name: null,
                email: null,
                phone: null,
                password: null,
                image: null,
                errors: {
                    id_sleep_quality: null,
                    id_stress: null,
                    id_physical_activity: null, 
                    id_health_condition: null, 
                    id_objective: null, 
                    id_age: null,
                    height: null, 
                    weight: null, 
                    id_gender: null, 
                    first_name: null, 
                    last_name: null,
                    email: null, 
                    password: null
                },
                filter: 'all',
                 //pagination
                page: 1,
                rows: 10,
                order_asc: '2',

            }
        },
        methods: {
            //helpers.
            clearFields() {
             
                this.id_sleep_quality = '0' 
                this.id_stress = '0' 
                this.id_physical_activity = '0' 
                this.id_health_condition = '0' 
                this.id_objective = '0' 
                this.id_age = '0' 
                this.height = null 
                this.weight = null 
                this.id_gender = '0' 
                this.first_name = null 
                this.last_name = null 
                this.email = null 
                this.password = null
                this.current_option = []
                this.phone = null

                this.prospects_modal = false;
                this.delete_modal = false
                this.active_modal = false

                for(let error in this.errors) {
                    this.errors[error] = null
                }
            },
            async selectTableItem(type, id, data = null) {
                //selecciona el id de un item, y despliega el modal necesario
                switch(type) {
                    case 'edit':
                        if(!data.active) {
                            this.$store.commit('setAlert', {
                                open: true,
                                message: 'No se permite la edición de elementos inactivos',
                                text_color: 'warning'
                            }, { root: true})
                            return
                        }
                        this.selected_id = id
                        await this.$store.dispatch('prospects/view', { id_prospect: this.selected_id})
                        this.first_name = this.prospect.first_name
                        this.last_name = this.prospect.last_name
                        this.phone = this.prospect.phone
                        this.email = this.prospect.email
                        this.id_gender = this.prospect.gender
                        this.id_age = this.prospect.age
                        this.id_objective = this.prospect.objective
                        this.id_health_condition = this.prospect.healty_condition
                        this.id_physical_activity = this.prospect.fisical_activity
                        this.weight = this.prospect.weight.toString()
                        this.height = this.prospect.height.toString()
                        this.id_stress = this.prospect.stress

                        let option = this.stress_options.find( sel_option => sel_option.id == this.prospect.stress)
                        
                        this.current_option = [option]

                        this.id_sleep_quality = this.prospect.dream_quality
                        this.prospects_modal = true
                        break;
                    case 'delete':
                        this.selected_id = id
                        this.delete_modal = true
                        break;
                    case 'activate':
                        this.selected_id = id
                        this.active_modal = true
                        break;
                    default:
                        console.info('Invalid case')
                        return
                }
            },
            async filterElements() {
                let search = ''
                this.loading = true
                if(this.search_query) {
                    search = this.search_query
                }

                if( this.total_pages !== null && this.total_pages < this.page) {
                    this.page = this.total_pages == 0 ? 1 : this.total_pages
                }

                let order = this.order_asc == '2' ? false : true;
                
                switch(this.filter) {
                    case 'active':
                        await this.$store.dispatch('prospects/list', { datatable: true, page: this.page, rows: this.rows, status: 'active', search: search, order_asc: order})
                        break;
                    case 'inactive':
                        await this.$store.dispatch('prospects/list', { datatable: true, page: this.page, rows: this.rows, status: 'inactive', search: search, order_asc: order})
                        break;
                    case 'all':
                        await this.$store.dispatch('prospects/list', { datatable: true, page: this.page, rows: this.rows, status: null, search: search, order_asc: order})
                        break;
                }

                if(this.prospects === null) {
                    this.empty_search = true
                    this.loading = false
                    return
                }

                else {
                    this.empty_search = false
                    this.loading = false
                }
                
               this.prospects_data = this.prospects.map( category => {
                    return {
                        ...category,
                        actions: '-',
                        open: false
                    }
                })

                
            },
            checkComponentValue(option) {
                this.id_stress = option.id
                this.current_option = [option]
            },
            // posts CRUD
            async createEditElement() {
                for(let error in this.errors) {
                    this.errors[error] = null
                }

                let complete = true

                if(_.isNull(this.first_name) || _.isEmpty(this.first_name)) {
                    this.errors.first_name = '*Campo requerido'
                    complete = false
                }

                if(_.isNull(this.last_name) || _.isEmpty(this.last_name)) {
                    this.errors.last_name = '*Campo requerido.'
                    complete = false
                }

                if(_.isNull(this.email) || _.isEmpty(this.email)) {
                    this.errors.email = '*Campo requerido.'
                    complete = false
                }

                if(!this.isEmail(this.email)) {
                    this.errors.email = '*No es un correo válido.'
                    complete = false
                }

                if(_.isNull(this.phone) || _.isEmpty(this.phone)) {
                    this.errors.phone = '*Campo requerido.'
                    complete = false
                }

                if(_.isNull(this.id_age) || _.isEmpty(this.id_age) || this.id_age == '0') {
                    this.errors.id_age = '*Campo requerido.'
                    complete = false
                }

                if(_.isNull(this.id_gender) || _.isEmpty(this.id_gender) || this.id_gender == '0') {
                    this.errors.id_gender = '*Campo requerido.'
                    console.log('gender:', this.gender)
                    complete = false
                }

                if(_.isNull(this.id_sleep_quality) || _.isEmpty(this.id_sleep_quality) || this.id_sleep_quality == '0') {
                    this.errors.id_sleep_quality = '*Campo requerido.'
                    complete = false
                }

                if(_.isNull(this.id_stress) || _.isEmpty(this.id_stress) || this.id_stress == '0') {
                    this.errors.id_stress = '*Campo requerido.'
                    console.log('stress', this.id_stress)
                    complete = false
                }

                if(_.isNull(this.id_physical_activity) || _.isEmpty(this.id_physical_activity) || this.id_physical_activity == '0') {
                    this.errors.id_physical_activity = '*Campo requerido.'
                    complete = false
                }

                if(_.isNull(this.id_health_condition) || _.isEmpty(this.id_health_condition) || this.id_health_condition == '0') {
                    this.errors.id_health_condition = '*Campo requerido.'
                    complete = false
                }

                if(_.isNull(this.height) || _.isEmpty(this.height)) {
                    this.errors.height = '*Campo requerido'
                    complete = false
                }

                if(_.isNull(this.weight) || _.isEmpty(this.weight)) {
                    this.errors.weight = '*Campo requerido'
                    complete = false
                }

                if(complete) {

                    if(this.selected_id) {
                        let payload = {
                            body: {
                                first_name: this.first_name,
                                last_name: this.last_name,
                                phone: this.phone,
                                email: this.email,
                                password: this.password,
                                gender: this.id_gender,
                                weight: this.weight,
                                height: this.height,
                                age: this.id_age,
                                objective: this.id_objective,
                                healty_condition: this.id_health_condition,
                                fisical_activity: this.id_physical_activity,
                                stress: this.id_stress,
                                dream_quality: this.id_sleep_quality
                            },
                            query: {
                                id_prospect: this.selected_id
                            }
                        }

                        await this.$store.dispatch('prospects/update', payload)
                        this.clearFields()
                        this.filterElements()
                    }

                    else {

                        if(_.isNull(this.password) || _.isEmpty(this.password)) {
                            this.errors.password = '*Campo requerido'
                            complete = false
                        }

                        let payload = {
                            first_name: this.first_name,
                            last_name: this.last_name,
                            phone: this.phone,
                            email: this.email,
                            password: this.password,
                            gender: this.id_gender,
                            weight: this.weight,
                            height: this.height,
                            age: this.id_age,
                            objective: this.id_objective,
                            healty_condition: this.id_health_condition,
                            fisical_activity: this.id_physical_activity,
                            stress: this.id_stress,
                            dream_quality: this.id_sleep_quality
                        }
                        
                        await this.$store.dispatch('prospects/add', payload)
                        this.clearFields()
                        this.filterElements()
                    }
                }
            },
            async inactivateElement() {
                await this.$store.dispatch('prospects/delete', { id_prospect: this.selected_id, active: false})
                this.clearFields()
                this.filterElements()
            },
            async activateElement() {
                await this.$store.dispatch('prospects/delete', { id_prospect: this.selected_id, active: true})
                this.clearFields()
                this.filterElements()
            },

            //pagination
            prev(){
                this.page--;
                this.filterElements()
            },
            next(){
                this.page++;
                this.filterElements()
            }
        },
        computed: {
            ...mapGetters({
                prospect: 'prospects/getProspect',
                prospects: 'prospects/getProspects',
                last_page: 'prospects/getLastPage',
                total_pages: 'prospects/getTotalPages'
            })
        },
        async created() {
            try {
                //sets title for the view
                this.$store.commit('setTitle', 'PROSPECTOS')
                this.$store.commit('setIndex', 0)

                //list of propspects
                await this.$store.dispatch('prospects/list', {datatable: true, page: this.page, rows: this.rows, order_asc: false, active: true})

                if(this.prospects) {
                    this.prospects_data = this.prospects.map( prospect => {
                        return {
                            ...prospect,
                            actions: '-',
                            open: false
                        }
                    })
                }  
                

                this.loading = false
                this.fetch_error = false
            }
            catch(error) {
                console.error(error)
                this.loading = false
                this.fetch_error = true
            }
        }
    }
</script>

<style scoped>
    .custom_position {
        right: 10;
        
    }

    .custom_input {
        background-color: #e9ecef;
        border: none;
        height: 40px;
        border-radius: 10px;
    }

    @media screen and (max-width: 928px){
            .custom_position {
                right: 0;
            }
        }
</style>